<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina Titlu="Facturi neincasate" :AdaugaVisible="false" />
            </div>
          </template>
          <div class="filtre">
            <el-form @submit.prevent.native="refresh_info()" label-position="top">
              <el-row :gutter="20">
                <el-col class="input-element">
                  <el-form-item label='Interval data emitere factura' size="small">
                    <el-date-picker
                        size="small"
                        v-model="Filters.IntervalDataFactura"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="Data inceput"
                        end-placeholder="Data sfarsit"
                        :picker-options="datePickerOptions"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col> 
                <el-col class="input-element">
                  <el-form-item label='Interval data scadenta factura' size="small">
                    <el-date-picker
                        size="small"
                        v-model="Filters.IntervalScadenta"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="Data inceput"
                        end-placeholder="Data sfarsit"
                        :picker-options="datePickerOptions"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Nume client" size="small">
                    <el-input v-model="Filters.NumeClient" size="small"/>
                  </el-form-item>
                </el-col>
                <el-col class="input-element">
                  <el-form-item label="Agent" size="small">
                    <el-select size="small" v-model="Filters.IdAgent" filterable>
                      <el-option label="Toti" value="-1"></el-option>
                      <el-option v-for="item in Info.agenti" :key="item.Id + item.Denumire" :label="item.Denumire"
                        :value="item.Id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col> 
                <el-col class="input-element">
                  <el-form-item label="Tip incasare" size="small">
                    <el-select size="small" v-model="Filters.TipIncasare" filterable>
                      <el-option label="Oricare" value="-1"></el-option>
                      <el-option label="B" value="B"></el-option>
                      <el-option label="C" value="C"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col style="width: 100%">
                  <div class="footer-form">
                    <el-button type="primary" native-type="submit" @click="refresh_info()"  size="small">
                      Aplica
                    </el-button>
                    <el-button type="danger" native-type="submit" @click="reset()"  size="small">
                      Reseteaza
                    </el-button>

                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table 
      :header-cell-style="{ background: '#393E46' }" 
      :row-class-name="tableRowClassName"
      :data="Results"
      class="tabelRandMic">
      <el-table-column prop="Id" label="#" width="44" />
      <el-table-column prop="NumeClient" label="Client" min-width="220"/>
      <el-table-column label="Serie / Numar" width="130">
        <template slot-scope="scope">
          {{scope.row.Serie}} {{scope.row.Numar}}
        </template>
      </el-table-column>
      <el-table-column label="Data facturii" style="text-align: center;" width="130">
        <template slot-scope="scope">
          {{ scope.row.DataFactura | Data() }}
        </template>
      </el-table-column>
      <el-table-column label="Scadenta" style="text-align: center;" width="130">
        <template slot-scope="scope">
            {{ scope.row.Scadenta | Data() }}
        </template>
      </el-table-column>
      <!-- <el-table-column style="text-align: right;" prop="ValoareFaraTVA" label="Valoare fara TVA Ron" /> -->
      <el-table-column style="text-align: right;" label="Valoare cu TVA RON" width="130">
        <template slot-scope="scope">
          {{ (scope.row.ValoareCuTVACuDisc) | format_money }}
        </template>
      </el-table-column>  
      <el-table-column label="Incasata" style="text-align: center;" width="110">
        <template slot-scope="scope">
          <span v-if="scope.row.Incasare.Status == 'Da'">Da</span>
          <span v-else-if="scope.row.Incasare.Status == 'Partial'">Partial. Ramas: {{scope.row.Incasare.DeIncasat}}</span>
          <span v-else>Nu</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="refresh_info" @current-change="refresh_info" :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
  </base-page>

</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from "@/widgets/TitluPagina";
import moment from "moment";

export default {
  name: "facturi",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      datePickerOptions: settings.datePickerOptions,
      Results: [],
      Info: [],
      base_url: "",
      Filters: {
        IntervalDataFactura: [],
        IntervalScadenta: [],
        NumeClient: "",
        IdAgent: "-1",
        TipIncasare: "-1",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    tableRowClassName({row}) {
      if (new Date(row.Scadenta) < new Date() && row.Scadenta!= null) {
        return 'factura-neincasata-row';
      }
      return '';
    },
    async get_info() {
      var response = await this.post("Rapoarte/Raport_neincasate/get_info_raport_neincasate");
      this.Info.agenti = response.agenti;
      this.refresh_info();
    },

    async refresh_info() {
      var response = await this.post("Rapoarte/Raport_neincasate/get_raport_neincasate", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("rapoarte");
    },
    reset() {
      this.Filters = {
        IntervalDataFactura: [],
        IntervalScadenta: [],
        NumeClient: "",
        IdAgent: "-1",
        TipIncasare: "-1",
      };
      this.refresh_info();
    },



  

  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },

};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}
@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }

  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }

  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}


.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);

  .el-col {
    width: 50%;
  }

  .el-date-editor {
    width: 100%;
  }

  .input-element {
  width: 23%;
  margin-right: 2%;
}

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
